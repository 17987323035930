<template>
  <div class="about">
    <div class="banner">
      <!--      <p>给大家带来快乐的同时<br/>实现人生价值</p>-->
    </div>
    <div class="main">
      <div class="title">关于我们</div>
      <div>
        <p>广州九伴信息科技有限公司旗下的一款线上娱乐、互动为主，以多样的互动形式、满足用户对音乐、舞蹈、户外运动、好物推荐等直播观看体验的直播软件，致力于打造全民娱乐的互动直播平台。</p>
        <p>公司地址：广州市天河区翰景路1号金星大厦5楼F7-73</p>
      </div>
      <img class="main-img" src="../images/5237.jpg" />
    </div>
    <div class="footer">
      <p>Copyright© 2018 - 2022 广州九伴信息科技有限公司 版权所有</p>
      <p>网站备案号   <a href="https://beian.miit.gov.cn">[粤ICP备17090490号-3]</a></p>
      <p>网络文化经营许可证    粤网文 【2018】 8216-2978号</p>
      <p>全国文化市场监督举报电话：12318   注意自我保护，谨防上当受骗</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "convention"
}
</script>

<style scoped>

.banner {
  width: 100%;
  height: 300px;
  background-color: #eeeeee;
  /*position: relative;*/
  background-image: url("../images/home_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /*padding-top: 200px;*/
}

.banner p {
  width: 1200px;
  margin: 0 auto;
  /*text-align: center;*/
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  height: 500px;
  display: flex;
  align-items: center;
}

.main {
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0 80px;
}

.title {
  font-weight: bold;
  font-size: 32px;
  color: #409EFF;
  padding-bottom: 40px;
  margin-bottom: 32px;
  border-bottom: 1px solid #ededed;
}

.main p {
  color: #555555;
  font-size: 14px;
  line-height: 24px;
  margin: 24px 0 !important;
}

.footer {
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  background-color: #222222;
  color: #ffffff;
  padding: 32px 0;
}

.footer a {
  color: #ffffff;
  text-decoration-line: none;
}

.footer p {
  opacity: 0.6;
  margin: 8px 0;
}

.main-img {
  width: 1000px;
  margin: 0 100px;
}

</style>
