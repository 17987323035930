<template>
  <div class="copyright">
    <div class="banner">
      <!--      <p>给大家带来快乐的同时<br/>实现人生价值</p>-->
    </div>
    <div class="main">
      <div class="title">版权协议</div>
      <div>
        <p>1. 所有作品仅供您个人学习、研究或欣赏，不得用于商业或者其他用途；</p>
        <p>2. 本网站所转载的所有文章、图片、视频、音频等资料的版权均归版权所有人所有。本站采用的非本站原创的文章、图片、视频作品、音频作品等内容无法一一和版权所有人取得联系，如涉及版权侵权问题，或者版权所有人认为不应无偿使用，请及时用电子邮件或电话通知我们，我们将迅速采取相应措施。</p>
        <p>3. 网站服务内容所包括的文字、软件、声音、图片、录像、图表及为用户提供的其他信息受中华人民共和国法律保护。您有权利浏览上述所有信息，但未经许可，您不得以任何方式复制、传播或以其他方式非法使用。</p>
        <p>4. 维护版权人人有责，尊重知识产权的保护！由于部分内容来自用户上传，网站对非法转载、盗版等侵权行为的发生不具备充分的监控能力。用户必须保证版权拥有者允许您上传声音文件，如有版权方对您上传的声音文件提出质疑并向网站提供相应材料，网站会在必要时可以不经您的同意删除你的声音文件。</p>
        <p>5. 用户应合法地使用网站所提供的任何服务，并对自己在本网上的行为承担法律责任。用户必须以中华人民共和国各项法律法规许可的方式使用网站。用户不得利用网站侵犯他人权利和利益。用户不得利用网站传输法律禁止传播的任何信息资料。用户应遵守社会公德，不得利用网站传输任何辱骂性、恐吓性、庸俗、淫秽的信息资料。</p>
        <p>6. 本网站所有内容，包括但不限于文字、图片、视频、音频、图表、标志、标识、商标、版面设计等，均受《中华人民共和国著作权法》、《中华人民共和国商标法》及适用之国际公约中有关著作权、商标权以及或其它财产所有权法律的保护，相应的版权或许可使用权均属开迅所有。</p>
        <p>7. 凡未经开迅书面授权，任何单位及个人不得以任何方式或理由对上述开迅合法拥有知识产权的内容进行使用、转载、复制、修改、重制、传播、展示或使用界面网站的局部或全部的内容或服务，或在非界面网站所属服务器上建立镜像等。如果已发生上述行为，请自行删除并消除影响。同时，开迅保留进一步追究相关行为主体的法律责任的权利。</p>
      </div>
    </div>
    <div class="footer">
      <p>Copyright© 2018 - 2022 广州九伴信息科技有限公司 版权所有</p>
      <p>网站备案号   <a href="https://beian.miit.gov.cn">[粤ICP备17090490号-3]</a></p>
      <p>网络文化经营许可证    粤网文 【2018】 8216-2978号</p>
      <p>全国文化市场监督举报电话：12318   注意自我保护，谨防上当受骗</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "convention"
}
</script>

<style scoped>

.banner {
  width: 100%;
  height: 300px;
  background-color: #eeeeee;
  /*position: relative;*/
  background-image: url("../images/home_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /*padding-top: 200px;*/
}

.banner p {
  width: 1200px;
  margin: 0 auto;
  /*text-align: center;*/
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  height: 500px;
  display: flex;
  align-items: center;
}

.main {
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0 80px;
}

.title {
  font-weight: bold;
  font-size: 32px;
  color: #409EFF;
  padding-bottom: 40px;
  margin-bottom: 32px;
  border-bottom: 1px solid #ededed;
}

.main p {
  color: #555555;
  font-size: 14px;
  margin: 24px 0 !important;
  line-height: 24px;
}

.footer {
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  background-color: #222222;
  color: #ffffff;
  padding: 32px 0;
}

.footer a {
  color: #ffffff;
  text-decoration-line: none;
}

.footer p {
  opacity: 0.6;
  margin: 8px 0;
}

</style>
