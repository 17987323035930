<template>
  <div class="home">
    <div class="banner">
      <img class="banner-img" src="../images/home_bg.jpg">
<!--      <p>给大家带来快乐的同时<br/>实现人生价值</p>-->
    </div>
    <div class="list">
      <div class="list-item" v-for="(item,index) in listData" v-bind:key="index">
        <div class="list-top">
          <img :src="item.image">
          <div class="skill">擅长：{{item.skill}}</div>
        </div>
        <div class="list-bottom">
          <div class="list-bottom-left">
            <img :src="item.image">
            <div>{{item.name}}</div>
          </div>
          <el-button type="primary" size="medium" @click="playVideo(item.video)">风采展示</el-button>
        </div>
      </div>
    </div>
    <div class="footer">
      <p>Copyright© 2018 - 2022 广州九伴信息科技有限公司 版权所有</p>
      <p>网站备案号   <a href="https://beian.miit.gov.cn">[粤ICP备17090490号-3]</a></p>
      <p>网络文化经营许可证    粤网文 【2018】 8216-2978号</p>
      <p>全国文化市场监督举报电话：12318   注意自我保护，谨防上当受骗</p>
    </div>

    <el-dialog
        title="视频播放"
        v-model="dialogVisible"
        width="960px"
        destroy-on-close>
      <span>

        <video width="920" height="640" controls autoplay>
          <source :src="videoUrl" type="video/mp4">
          <source :src="videoUrl" type="video/ogg">
          <source :src="videoUrl" type="video/webm">
<!--          <object :data="videoUrl" width="320" height="240">-->
<!--            <embed :src="videoUrl" width="320" height="240">-->
<!--          </object>-->
        </video>

      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>


export default {
  name: 'Home',
  data() {
    return {
      dialogVisible: false,
      videoUrl: '',
      listData: [
        {
          image: require('../images/1.jpg'),
          video: require('../images/1.mp4'),
          name: '陌沫',
          skill: '音乐、跳舞'
        },
        {
          image: require('../images/2.jpg'),
          video: require('../images/2.mp4'),
          name: '无果天下',
          skill: '音乐、跳舞'
        },
        {
          image: require('../images/3.jpg'),
          video: require('../images/3.mp4'),
          name: '念念',
          skill: '音乐、跳舞'
        },
        {
          image: require('../images/4.jpg'),
          video: require('../images/4.mp4'),
          name: '柔雨小可爱',
          skill: '音乐、跳舞'
        },
        {
          image: require('../images/5.jpg'),
          video: require('../images/5.mp4'),
          name: '萧宇LY',
          skill: '音乐、跳舞'
        },
        {
          image: require('../images/6.jpg'),
          video: require('../images/6.mp4'),
          name: '苏娟',
          skill: '音乐、跳舞'
        },
        {
          image: require('../images/7.jpg'),
          video: require('../images/7.mp4'),
          name: '西江月',
          skill: '音乐、跳舞'
        },
        {
          image: require('../images/8.jpg'),
          video: require('../images/8.mp4'),
          name: '一抹书香',
          skill: '音乐、跳舞'
        },{
          image: require('../images/9.jpg'),
          video: require('../images/9.mp4'),
          name: '小开心',
          skill: '音乐、跳舞'
        }
      ]
    }
  },
  methods: {
    playVideo(url) {
      console.log(url)
      this.dialogVisible = true
      this.videoUrl = url
      console.log(this.videoUrl)
    }
  }
}
</script>

<style>

.home {
  background-color: #f6f6f6;
}

.banner {
  width: 100%;
  /*height: 400px;*/
  background-color: #eeeeee;
  /*position: relative;*/
  /*background-image: url("../images/home_bg.jpg");*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*padding-top: 200px;*/
}

/*.banner p {*/
/*  width: 1200px;*/
/*  margin: 0 auto;*/
/*  !*text-align: center;*!*/
/*  color: #ffffff;*/
/*  font-size: 40px;*/
/*  font-weight: bold;*/
/*  height: 400px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

.banner-img {
  width: 100%;
}

.footer {
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  background-color: #222222;
  color: #ffffff;
  padding: 32px 0;
}

.footer a {
  color: #ffffff;
  text-decoration-line: none;
}

.footer p {
  opacity: 0.6;
  margin: 8px 0;
}

.list {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding-top: 42px;
}

.list-item {
  background-color: #ffffff;
  margin-right: 42px;
  margin-bottom: 42px;
  /*border-radius: 8px;*/
  overflow: hidden;
}

.list-item:nth-child(3),.list-item:nth-child(6),.list-item:nth-child(9) {
  margin-right: 0;
}

.list-top {
  height: 372px;
  position: relative;
}

.list-top img {
  width: 372px;
  height: 372px;
}

.skill {
  position: absolute;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  width: 100%;
  padding: 8px 16px;
  color: #ffffff;
  font-size: 14px;
}

.list-bottom img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.list-bottom {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-bottom-left {
  display: flex;
  align-items: center;
}

.list-bottom-left div {
  margin-left: 8px;
}

</style>
