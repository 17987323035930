<template>
  <div class="self-examination">
    <div class="banner">
      <!--      <p>给大家带来快乐的同时<br/>实现人生价值</p>-->
    </div>
    <div class="main">
      <div class="title">自审自查制度</div>
      <div>
        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">为加强网络文化内容建设与管理，规范网络文化经营单位产品及服务内容自审工作，增强本公司自主管理能力和自律责任，确保网络文化健康，快速发展，根据《互联网文化管理暂行规定》，结合本单位网展需要，特制定本制度。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">一、网络文化经营内容自审管理内容，工作流程及责任追究</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">内容自审机构的组成</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">根据文化部制定的《网络文化经营单位内容自审管理办法》单位挑选了由3名政治敏感性较强思想上有独特见解的员工组成自审部门。自审部门的审核人员工作要严谨，只有经过文化部的培训，取得《内容审核人员证书》的人员才能真正担任到公司的自审人员。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">自审部门的总负责人由总经理担任，主要负责自审工作的的最后审核，包括对网络文化产品及服务服务的内容合法性的准确判断及运行等。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">自审部门的自审人员，等网络文化产品及服务的内容的自审人员要对其审核内容认真负责。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">自审人员的职责</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">掌握内容审核的政策法规和相关知识</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">独立表达审核意见</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">参加文化行政部门组织的业务培训</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">以最终保证本企业的产品和服务的合法性和合规性为目的，对在自身工作中发现的违法违规的产品及服务内容进行记录并提请终止，作出提交修改，督促修改，复审等审核意见，重大问题向深圳市文化广电旅游体育局报告；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">负责保管审查记录</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">.对产品及服务内容的合法合规性不能准确判断的，可向深圳市文化广电旅游体育局申请行政指导；</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">.每年应至少参加1次由深圳市文化广电旅游体育局组织的业务培训；</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">组织内部培训工作，对企业开发、运营人员进行内容审查方面的培训。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">二、审查流程</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">对企业自主研发的产品，在研发阶段进行培训，对故事背景、美术素材等进行初步的筛查；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">自审人员在产品公测之前，依据内容审查的相关规定，对产品及其服务内容（包括宣传推广和活动策划）进行审查，对违法违规的内容进行记录，并签发初审意见；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">对初审有问题的产品，退回研发企业或部门进行修改，并对修改后的内容进行复查；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">对内容的合法合规性不能准确判断的，应向深圳市文化广电旅游体育局申请行政指导；</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">对复查仍有问题的，应按照上述第2-4项规定的内容重新进行审核；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">在产品公测前，对产品客户端、公司官网、产品官网进行审查，审查内容包括证照使用、实名注册、实名信息补填、用户协议必备条款、适龄提示、家长监护工程等内容，合格后方可公测；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">对审查完成的产品，自审人员提出同意进行公测的意见并签字确认，上交内容审查管理工作的主要负责人；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">内容审查管理工作的主要负责人签字确认后，根据规定要求，向文化行政管理部门提交产品备案；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">日常对产品和服务内容进行监督检查，包括产品版本更新后的内容、产品客户端、公司官网、产品官网、宣传推广和活动策划等各个方面和环节，发现问题提交检查意见，报本企业内容审查管理工作主要负责人；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">自审人员的所有审查意见应归档留存，保存时间不少于两年；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">自审工作流程图。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">三、审查标准</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">表演中不得含有以下内容：</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">表演中涉黄，涉政，谈论宗教，违法，侵权等敏感话题；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">表演内容不得造成强烈的感官、精神刺激，及可致人身心不适的动作、语言、画面和音效。</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">表演者不得发布包含色情内容的黄色网链接、淫秽视频内容的 。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">机构设置</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">审
核部门由运营管理部和总经理组成。运营管理部人员负责对视频及其服务内容（包括宣传推广和活动策划）进行审查并签字，对违法违规的内容进行记录，并签发初
审意见。然后报运营管理部经理复审，复审人员对网站内容进行再次审核，并签字发表复审意见，总经理负责最终审核公司拟上线的视频并签字。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">依
照《网络文化经营单位内容自审管理办法》的有关要求制定本制度；设立专门岗位及专人承担产品内容自审工作；自审由运营管理部人员实施审核，审核人员填写审
核意见并签字后，报本单位内容审查管理工作的主要负责人签字。其中主要负责人为公司主要领导，该领导为内容审查管理工作的主要负责人。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（一）审查人员职责</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">①本公司审核人员掌握内容审核的政策法规和相关知识；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">②审核人员均可在内容自审这一工作中表达其独立的审核意见；对初审有问题的内容，退回网站编辑人员进行修改，并对修改后的内容进行复查；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">③ 审核人员须以最终保证本企业的产品和服务的合法性和合规性为目的，对在自审工作中发现违法违规的产品及服务内容进行记录并提请中止，作出提交修改，督促修改，复审等审核意见。重大问题向深圳市文化广电旅游体育局报告；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">④审核人员负责保管审查记录；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">⑤审核人员遇有对产品及服务内容的合法合规性不能准确判断的，可向深圳市文化广电旅游体育局申请行政指导；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">⑥ 审核人员每年至少应当参加1次业务培训；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">⑦ 审核人员有组织内部培训工作，对企业开发、运营人员进行内容审查方面的培训的责任。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（二）审核流程</span></p>





        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">表演信息收集小组负责前期根据有关规定收集、整理、制作要上线的表演产品；表演信息审核小组将拟上线的表演信息报部门领导审核、签字，经领导审核同意、签字后方可上线发布；所有表演经审批结束后，保证符合各类法律法规之后，方可提交给技术部门，进行产品的网上上线操作。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">鉴别方式分为自动鉴别和人工鉴别，鉴别内容包括：图片、文字、视频等方面。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">自动鉴别：</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">安装屏蔽词库，通过接入第三方专业技术服务公司接口的形式，来实现对服务端图片文件、屏蔽关键词库的自动鉴别工作。发现违规图片后，系统会记录相关信息，然后删除违规图片。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">人工鉴别：</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">在运营管理后台中，用户头像、封面照等图片文件皆可查询预览，并对违规的图片进行管理操作。涉黄、涉恐、有低俗内容。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">如若通过严格的自审制度（自动+人工）之后，网站上仍然存留涉黄、涉恐、涉政、暴力、低俗文字、图片、视频等内容，突发事案处理流程如下：</span>
        </p>





        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">①技术上掐断处理、保留证据和用户信息，产品立刻下线并进行调整、修改，复审合格后，方可重新上线</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">②对事态分析。调查、核实内部及外部责任及分析，重新解决方案，并记录在案，相关记录保存至少两年。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">③逐级上报上级领导（运营管理部主管-总经理 ）</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">④报案处理。含有严重宣扬淫秽、暴力、教唆犯罪或者危害社会公德的内容，违反《互联网文化管理暂行规定》的产品，上报法务部，由法务部报网监局/公安局进行处理</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">⑤
策划部：从部门领导开始，严格学习《互联网文化管理暂行规定》、《网络文化经营单位内容自审管理办法》和《网络表演经营活动管理办法》等规章制度，信息收
集小组在前期搜集视频过程中及签署版权合作协议，严格按照公司制定的审核制度进行，从源头上阻止违规产品流通、上线、传播。</span></p>











        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">主播管理制度</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">一、基本要求</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">主播在本平台直播需遵守以下要求:</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(1) 遵守国家与地方相关法律法规;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(2) 坚持正确导向，坚持积极、健康、向上、向善;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(3) 尊重观众的宗教信仰和风俗习惯;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(4) 言行、着装文明;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(5) 掌握平台互动功能。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">二、主播要求</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.1准入要求</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.1.1 主播实名认证</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">主播实名认证需满足下列要求，否则不予通过：</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(1) 申请人应年满 18 周岁，且具有完全民事行为能力;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(2) 虚拟手机号不能进行绑定并开启直播;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(3) 同一个人的有效身份证件(身份证、护照、港澳台居民居住证等)在同一平台上只能认证绑定 一个直播账号;</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(4) 网络主播黑名单的用户，不能通过直播平台的认证审核;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(5) 进行身份认证的用户需要进行人脸活体检测，提供完整清晰正确的有效身份证件姓名及号码，其信息必须与公安系统录入的信息匹配;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(6) 因技术问题不能正常进行身份认证的用户联系平台客服后，按照要求提供相关身份资料，提供 资料交由人工审核。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.1.2 主播银行卡认证</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">主播银行卡认证应满足以下要求，否则不予通过:</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(1)收款人姓名与收款人有效身份证件一经实名认证，无法进行修改;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(2)银行卡开户人等信息必须与主播实名认证时的绑定人信息一致。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.2 直播内容</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">直播内容需要符合下列要求:</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(1) 遵照国家网信办发布的《互联网直播服务管理规定》;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(2) 严禁主播在无保障措施下的情况下直播高风险或威胁生命健康的内容，高危户外活动和需要专 业人士指导的活动需提前向平台报备，取得平台同意后方可直播;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(3) 严禁以任何形式直播无版权内容及低俗不良内容;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(4) 文玩鉴赏类的直播形式必须通过报备，报备者需要一定专业素养，经过相关协会或机构认证的专业人士，无报备者应暂时关闭直播间;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(5) 播放射击、展示枪械、狩猎等相关直播内容，进行户外生存类(在非专业场地野外生存、打猎)的内容，需向本平台提供当地机关许可的书面证明以及直播计划书,需提前联系本平台客服，提供相关报备资料并获得报备通过，报备不通过或无报备者应暂时关闭直播间;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(6) 主播不应在非电商专业直播平台中向观众推销产品;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(7) 主播不应在非金融专业直播平台中宣传投资方式和理财产品，不应向用户承诺和保证收益，不应夸大宣传和虚假宣传;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(8) 直播画面不应出现广告、支付平台二维码、支付平台账号、商务合作方式等内容;</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(9) 所有的交易行为必须通过第三方正规的电商网站进行。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.3 直播间</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.3.1 标题</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">主播设定直播间标题禁止出现以下内容:</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(1) 危害国家安全，泄露国家秘密;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(2) 有损党和国家机构、领导人形象;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(3) 侵犯他人合法权益;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(4) 交易性质的广告;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(5) 低俗、色情信息。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.3.2 封面及主播头像</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">封面及主播头像禁止涉及下列内容的图片:</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(1) 受国家法律法规保护的图案，如人民币、国徽、国旗等;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(2) 含有国家法律法规所不允许的内容;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(3) 宣传鼓动国家分裂性质及地域歧视、性别歧视、民族歧视及 种族歧视的内容;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(4) 违反国家法律法规的邪教组织的人物、标识;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(5) 宣传黄、赌、毒及血腥暴力等内容;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(6) 违规广告或侵权信息;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(7) 含侮辱、挑衅、谩骂人身攻击等不文明不健康信息;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(8) 违反直播平台其他规定规范内容。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.4 行为</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">主播禁止出现以下直播行为:</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(1) 未经直播平台许可，以直播平台名义开展活动;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(2) 涉赌、涉毒、涉黄、涉暴、恐怖等违法行为;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(3) 妨害公共安全或有安全隐患的行为，例如在驾驶机动车期间 进行直播、观看弹幕等;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(4) 诋毁、恶搞、丑化英烈，亵渎、否定英雄事迹和精神，宣扬、美化侵略战争和侵略等行为;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(5) 侵害他人隐私，骚扰、扰民的行为;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(6) 言行过激，传播负面情绪，有违人道主义和道德底线的表演;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(7) 违法擦边行为，例如:意图约架、插队、逃票、翻越围墙、乱写乱画等;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(8) 不及时处理直播间不良倾向弹幕。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.5 场所</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">主播禁止在下列场所进行直播:</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(1) 涉及国家及公共安全的场所;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(2) 涉密场所;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(3) 影响社会正常生产、生活秩序的场所;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(4) 涉及黄、赌、毒等影响社会治安的场所，如成人娱乐场所、夜店酒吧等;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(5) 暴露他人隐私的场所，如洗手间、更衣室等。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.6 着装</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">主播着装禁止过透过露，违反公序良俗，禁止出现以下情形:</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(1) 女主播穿着过于暴露，例如露出胸部、臀部、内衣和内裤等敏感部位;</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(2) 穿着情趣制服，情趣内衣，透视装，肉色紧身衣，内衣外穿等;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(3) 穿着包含国家法律法规禁止出现的文字及图案信息的服装;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(4) 穿着包含不文明或不健康信息的服装;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(5) 国家公务人员在非政务公开活动中穿着相关制服进行直播;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(6) 其他不雅妆容。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">三、出现未成年人的直播要求</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">3.1 报备要求</span></p>

        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">直播内容有未成年人单独出镜的需要提前向本平台进行报备，要求如下:</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(1) 提供监护人身份证照片、户口本本人页照片、户口本户主页照片;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(2) 提供被监护人户口本本人页照片;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(3) 由监护人签署《未成年人直播家长监护申请书》，申请书中写明监护人姓名，本人与监护人关 系,将以上内容提交给直播平台审核，审核资料无误后报备通过。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">3.2 含有未成年人直播内容</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">含有未成年人直播内容禁止出现以下情形:</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">(1) 违反《中华人民共和国未成年人保护法》及相关国家法律法规;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(2) 抽烟，酗酒，打架斗殴等宣扬不良生活作风的行为;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(3) 未经允许的课堂直播;</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">(4) 不适宜未成年人的生活方式和价值观。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">四、备注</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">4.1本管理办法由聚车（深圳）信息服务有限公司颁布，最终解释权归聚车（深圳）信息服务有限公司所有，有权对本办法进行修改并公示。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">4.2本办法自发布之日起生效。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">违规处罚制度</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">一、概述</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">1.为了加强对视频业务管理，给用户提供一个健康和谐的才艺展示与欣赏平台，特制订本管理规范。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.本管理规范所指的视频直播包含娱乐类直播、教育类直播、游戏类直播、财经直播、手机直播等平台上的所有视频业务。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">二、视频直播间违规定义</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">1 主播必须遵守以下规定，否则将受到处罚，违规的情节严重程度是官方依据违规意图、违规时间和违规主体等客观因素来进行综合评定的。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2 主播有义务保证直播环境健康有序，主播需对直播间中音视频内容（含连麦转播内容）等所有内容负责，若以上部分有违规行为，官方将依据本协议进行违规处罚。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">严重违规行为：（A类违规：若主播出现严重违规行为，则一次性清空主播管理后台数据，永久封号。）</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">2.1.1 严禁进行反党反政府或带有侮辱诋毁党和国家的行为。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（1）违反宪法确定的基本原则的；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的，损害国家荣誉和利益的。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（3）煽动民族仇恨、民族歧视、破坏民族团结的，破坏国家宗教政策，宣扬邪教和封建迷信的。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（4）散布谣言，扰乱社会秩序，破坏社会稳定的，散布暴力、恐怖或者教唆犯罪的。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（5）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的，其他可能引起或已经引起不良影响的政治话题。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.1.2 严禁直播违反国家法律法规的内容。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（1）展示毒品样品、表演及传播毒品吸食或注射方式、讲解毒品制作过程等一切与毒品相关的内容；</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（2）组织、宣传、诱导用户加入传销（或有传销嫌疑）的机构；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（3）与赌博或涉嫌赌博有关的任何活动。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（4）播放自己或他人的腐朽、低级趣味活动，如享受不道德的、腐朽的消费等；</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（5）严禁直播带有政治性内容：（如：扮演日本人，穿其他国家军装）;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（6）严禁以戏说或娱乐形式演绎国歌，或以国旗，党旗 军旗为直播背景。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（7）严禁在军事区域直播或穿着带有中华人民共和国（包含港澳台）国家机关人员、军队的工作制服进行直播，包括但不限于公安、检察院、司法、工商、税务、海关、城管制服、路政制服、军装迷彩服等服装进行直播。</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">2.1.3 严禁进行威胁生命健康，或利用枪支、刀具表演。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（1）使用刀具、仿真刀具、枪支、仿真枪支、表演具有高度危险性的节目等；</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（2）表演危害他人人身安全的内容。如：殴打他人、威胁他人等；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（3）表演危害自身安全的内容。如自残自杀等；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（4）表演危害动物生命健康的内容。如虐待小动物等；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（5）其他威胁生命健康的表演。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.1.4严禁涉黄行为.</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（1）严禁与他人进行互相性挑逗形式的表演或行为，如相互抚摸敏感部位、进行能让人产生性幻想的动作、在床上以任何抚摸形式进行表演等；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（2）严禁通过各种方式裸露具有性特征的身体敏感部位，如展示胸部、臀部、大腿内侧、生殖器官或穿透视装等；</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（3）严禁直播带有色情、性暗示的游戏、录像、图片、绘画等。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.1.5 严禁侵犯他人合法权益。</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">1.严禁抹黑、诋毁攻击他人，或挑起事端，及泄露他人隐私等，例如：</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">1.不得侵犯、泄露他人隐私，不得讨论他人是非或鼓动游客讨论他人是非，挑起事端；不得未经当事人同意，曝光他人感情史，公开他人姓名、住址、电话等个人资料及其他隐私信息；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.不得有诋毁他人、谩骂攻击行为（包括隐晦性，不指名道姓诋毁、攻击他人）；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">3.不得转播给他人造成损害的所有不实报道；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">4.不得以任何形式挑起公会、个人之间的恶性争端；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">5.直播间出现恶意诋毁、抹黑等行为，主播需及时制止，若未及时制止，主播将受连带处罚。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">6.危及公众利益，未经当事人同意，禁止进行任何形式的采访、与公众进行互动及其他隐私信息；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">7.使用文字、语音、图片等任何形式宣传其他同类型语音、视频平台及引导平台主播/用户去其他平台，损害平台利益等行为（情节严重给与薪资冻结并封号处罚）</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">8.播放未经授权的影视作品；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">9.进行其他侵害他人合法权益的行为。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">10.冒充媒体、电视台、记者等名义进行直播活动；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">11.发布广告信息：包括所有违反国家法律及相关管理规则的信息。如：赌博类、博彩类、成人用品类、枪支、军刀、弓弩类、成人影视类、云盘类广告、性用品、保健品类、香烟广告、非法性药品广告和性病治疗广告等相关内容。</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">一般违规（B类违规：若主播出现B类违规行为，根据违规给予处罚）</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.2.5 严禁以任何形式表演带有色情、涉黄擦边、引起他人性欲、低级趣味的内容。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（1）严禁反科学、封建迷信的内容，包括但不限于算卦、占星、占卜、塔罗牌、佛牌、看风水、碟仙、笔仙、筷仙等；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（2）严禁组织以骚扰、戏弄为目的的网络电话，包括但不限于播打他人电话向他人推销产品或以恶作剧为主的网络电话；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（3）严禁演唱、播放带有色情、性暗示的音乐，包括但不限于被国家列入黑名单的120首禁歌；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（4）严禁酒后直播，或演绎耍酒疯、酣睡等醉态；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（5）严禁直播时在除本平台之外的其他平台输出视频直播信号，该行为判定为双开行为；</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（6）严禁主播在身体上涂画不雅、低俗图像，进行直播间低俗互动；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（7）严禁主播开展活动类直播时采用性用品道具、包夜、原味等有性暗示词汇的奖品作为活动福利。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2、动作违规：</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">严禁表演时出现挑动观众性欲、导致普通人腐化、堕落，而又没有任何艺术价值的内容；包括但不限于以下内容：</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（1）严禁模仿各种低俗、色情动作或带有诱惑性、挑逗性表演的行为；包括但不限于"磨豆腐"、"抖胸"、" 舔肩膀"、" 拔身体敏感部位毛发"或利用桌椅进行低俗动作表演以及拍打敏感部位等暗示性行为；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（2）严禁将镜头刻意聚焦于性敏感部位，或进行带有性暗示的抚摸、拉扯动作，或使用道具引起观众对性敏感部位的注意；包括但不限于使用吹风机等道具对敏感部位进行表演等；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（3）严禁使用性用品作为表演道具；</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（4）严禁主播在镜头前整理内衣或连麦时利用内衣进行相关表演；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">3、裸露违规：</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（1）严禁刻意露出胸罩、底裤；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（2）严禁穿着透明、内衣外穿、情趣制服、情趣内衣、暴露装、透视装、肉色紧身衣、半截抹胸、渔网袜、吊带袜、吊带、三角短裤或呈现三角状短裤、禁止裸漏肚脐、有性暗示文字或图片的服装等；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（3）严禁穿着过于暴露的服装进行表演（包括但不限于：低胸、内衣、三点式露脐泳装、单穿抹胸、三角牛仔裤、短于大腿1/3长度的下装）。</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（4）严禁直播期间制造、发出带有低俗、挑逗性的声音及低俗谐音擦边</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">轻微违规（C类违规：若主播出现C类违规行为，根据违规给予处罚）</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2.2.6影响程度轻微的扰乱视频直播平台秩序的违规行为，在视频直播中违反以下规定，将构成轻微违规，包括但不限于例如：</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（1）严禁持续爆粗口、谩骂，对象包括用户，以及主播和其他观看直播的人员；</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（2）严禁在直播镜头前公然抽烟，喝酒，吃饭，吃零食，睡觉等；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（3）严禁在直播时化妆，敷面膜、不带妆直播；</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（4）严禁由他人代替主播自己直播（主播本人出现在画面内不视为代播）；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（5）严禁直播时不理睬用户，无互动或无内容直播，玩手机时间超过5分钟等懒散行为；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（6）严禁主播在直播中挂图片，录像，等不出境行为（超过1分钟则为违规）；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（7）严禁播放未经授权的影视剧、综艺、MV、赛事或其他侵犯他人知识产权的行为；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（8）严禁主播在直播间讨论薪资待遇相关的问题；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（9）严禁直播时离开直播画面超过5分钟；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（10）严禁在直播期间，躺在床上或沙发上进行直播；</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（11）严禁直播期间参与聚会、家庭聚餐、无互动行为不理睬用户；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（12）严禁在ktv、酒吧等娱乐场所进行直播；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（13）严禁直播期间，关闭直播间灯光或扮鬼等；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（14）严禁直播期间讨论外站平台及与外站平台主播连麦；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（15）严禁主播直播时长时间使用口罩、面具、头盔等遮挡主播容颜的道具进行直播；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（16）资料信息（个人签名、头像、昵称等）存在任何违法违规行为，包括但不限于：</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（1）头像及封面尺度过大，非主流类、大面积纹身露出（前胸、后背、花臂、花腿）、性器官、乳房等；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（2）冒充明星；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（3）含有低俗信息，包括宣传以及涉嫌宣传一些与主流价值观不符、低级趣味如黄色笑话、婚外情等信；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（4）含有商业广告信息，如打广告、卖产品、未经官方审核的商业合作及推广行为。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（5）严禁直播时危险驾驶、违法驾驶，包括但不限于闯红灯、遮挡变更号牌、酒驾、驾驶员未系安全带等行为；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（6）严禁进行其他类涉政、涉黄、违法、侵权、或威胁生命健康等其他类型违反本平台相关秩序规范的表演。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">三、违规处罚</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">违规类型</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">违规≥1次</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">违规≥2次</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">违规≥3次</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">违规≥4次</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">A类处罚</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">扣除所有薪资并封号</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">B类处罚</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">100元</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">200元</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">500元/停播3天</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">扣除所有薪资并封号</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">C类处罚</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">警告</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">50元</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">100元</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">200元</span></p>





        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">特别说明：</span></p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">1、所有违规的直播内容，主播通过转屏展示则判断为同类违规；</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">2、如主播发生A类违规/B类违规/C类违规情节严重并造成恶劣影响的，官方可根据违规性质给与冻结薪资及封号处理；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">3、本违规按期计算，同一账号当期违规次数超过4次，在以上处罚基础上叠加处罚500元，并公会连带处罚；</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">四、备注</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">4.1本管理规范由聚车（深圳）信息服务有限公司颁布，最终解释权归聚车（深圳）信息服务有限公司所有，有权对本管理规范进行修改并公示。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">4.2本办法自发布之日起生效。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">平台管理制度</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">严重违规（永久封停直播间，永久封停账户）</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（1）严禁用户进行反党反政府或带有侮辱诋毁党和国家的行为，包括且不限于：</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">a.违反宪法确定的基本原则的；</span></p>

        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">b.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">c.损害国家荣誉和利益的；</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">d.煽动民族仇恨、民族歧视、破坏民族团结的；</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">e.破坏国家宗教政策，宣扬邪教和封建迷信的；</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">f.散布谣言，扰乱社会秩序，破坏社会稳定的；</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">g.散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的。</span>
        </p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">h.侮辱、谩骂或者诽谤他人，侵害他人合法权益的。</span>
        </p>

        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">i.煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的。</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">j.以非法民间组织名义活动的。</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">k.含有法律、行政法规禁止的其他内容的。</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">l.发布或传播黑客、盗号、木马等信息。</span></p>

        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">m.对本平台进行破坏，恶意删除直播间、扰乱直播正常秩序的行为。</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">n.通过刷屏、私聊等恶意手段，散布大量广告信息，严重影响、侵害其他用户正常使用一览视界直播的行为。</span>
        </p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">o.非法组织网络电话、黑客教学等恶意捣乱性质的行为。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（2）严禁用户违反国家法律法规的内容，包括且不限于：</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">a.展示毒品样品、表演及传播毒品吸食或注射方式、讲解毒品制作过程等一切与毒品相关的内容</span>
        </p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">b.组织、宣传、诱导用户加入传销（或有传销嫌疑）的机构</span>
        </p>

        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">c.与赌博或涉嫌赌博有关的任何活动，例如：赌球、赌马、网络百家乐等</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">d.其它低俗涉黄行为，例如：播放AV、播放露点内容的影视、浏览或传播色情图片、出售色情网盘资源等。</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">（3）严禁进行威胁生命健康，或利用枪支、刀具表演，包括且不限于：</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">a.使用刀具、仿真刀具、弩、枪支、仿真枪支表演具有高度危险性的节目等，境外有合法持枪资格的，使用的展示管制枪械，需提前报备，在取得允许之后方可直播</span>
        </p>

        <p style="margin:0pt"><span
            style="font-family:'Times New Roman'; font-size:14px">b.表演危害他人人身安全的内容，如：殴打他人、威胁他人等</span></p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">c.表演危害自身安全的内容，如自残、自杀等。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（4）禁止侵害平台合法权益和妨碍平台正常运营，利用平台漏洞获取非法利益，包括但不限于利用画面、文字、语言等手段进行宣传、诱导、介绍的行为，如：盗号等行为。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（5）禁止以任何方式诋毁、损害平台形象，包括且不限于微博、微信群组、微信朋友圈、QQ群组、QQ朋友圈，如：在粉丝群曲解平台政策规范，在微博发表片面不实信息等行为。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">其他违规（进行警告、处罚、封停等等措施）</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（1）禁止播放一切无版权内容：平台禁止播放一切无版权内容，包括但不仅限于影视、游戏、体育赛事、演唱会等。</span>
        </p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px"> （2）禁止播放一切暴力、血腥、大尺度内容：平台禁止播放一切暴力、血腥、大尺度内容，包括但不仅限于影视、游戏、体育赛事、演唱会等。</span>
        </p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（3）禁止出现其他直播平台的直播内容：本平台严禁出现其他直播平台的直播内容</span>
        </p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">严禁出现宣传其他直播平台、鼓动观众观看非本平台内容的行为， 情节严重者将永久封停直播间。</span>
        </p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（4）未经允许，严禁盗播本平台其他主播的直播内容。</span>
        </p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（5）其他相关内容：法律法规、相关监管部门规定的禁止播放的内容， 情节严重者将永久封停直播间。</span>
        </p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（6）无意义直播内容：严禁播放无意义直播内容，包括但不限于挂机、主播无自主行为意识，如直播睡觉等。</span>
        </p>

        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（7）禁止播放国家明令禁止的视听内容。</span></p>





        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">纠纷处理</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">直
播平台是直播产品的运营商，如若本平台用户之间发生纠纷无法协商一致的，可向国家相关行政机关或司法机关寻求解决，包括但不限于向公安机关报案、申请仲裁
或向人民法院提起诉讼等。将会为实名注册用户提供必要的协助和支持，并根据有关行政机关和司法机关的要求承担一定的举证责任或采取必要措施。本平台用户之
间发生纠纷的，也可向公司客服人员投诉并举证。用户需提供与其账号注册信息一致的个人有效身份证件、必要的国家行政或司法机关文件和其他平台要求提供的相
关证据。经平台审核确认后，可以给予必要的协助和支持。</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">用户与之间纠纷处理方式 </span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">如本平台用户对的服务有任何异议的，可以向直播平台的客服人员（联系电话：18607889202）投诉并举证。查证属实的，将立即更正并按照现行法律规定给予用户必要的补偿。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">如本平台用户对服务有任何异议的，也可以向双方约定（具体参见《用户协议》）的所在地人民法院提起诉讼。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">（一）流程</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">注册用户如就账号等数据的使用权归属等存在争议，可以通过官方网站"客服电话"或官方提供的其他方式请求中游客户服务人员处理。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">平台注册用户应遵守直播平台官方处理此类事务的要求、流程，对自己的主张提供相应的材料予以证明。如用户不能提供充分材料证明其主张成立，因此致使其受到损害，则由用户独立承担全部责任。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">平台注册用户证明其会员资格时，应根据官方的要求提供本人有效身份证件、购买官方产品或服务的凭证以及其他资料。如因用户无法证明其会员资格而影响官方为其提供服务，则由该用户承担全部责任。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">平台注册用户同意以平台直播官方系统所记载的数据作为处理用户之间或用户与官方之间纠纷的判断标准。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">平台注册用户自行承担其因处理此类纠纷而产生之通讯费、邮寄费、交通费等一切费用。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">平
台注册用户对直播平台做出的处罚存在争议，可以在处罚之日起60天内通过"客服电话"或直播平台官方提供的电话、电子邮件其他方式进行申诉申请，请求直播
平台官方客户服务人员处理。并根据官方的要求提供相关证明资料。如因用户未在60天内对处罚提出异议，或是能够提出异议但无法在60天内提供证明资料的，
则由该用户承担全部责任。</span></p>





        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">防沉迷</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">"
网络直播未成年人家长监护工程"是一项由国内直播平台共同发起并参与实施，由中华人民共和国文化部指导，旨在加强家长对未成年人参与网络直播的监护，引导
未成年人健康、绿色参与网络直播，和谐家庭关系的社会性公益行动。它提供了一种切实可行的方法，一种家长实施监控的管道，使家长纠正部分未成年子女沉迷直
播的行为成为可能。</span></p>





        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">该项社会公益行动充分反映了中国网络直播行业高度的社会责任感，对未成年玩家合法权益的关注及对用实际行动营造和谐社会的愿望。</span>
        </p>





        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">"家长监护机制"是首媒在这一公益行动中，针对目前未成年人缺乏自控及自律能力，容易陷入沉迷；少数监护人缺少时间照顾未成年人，不能及时监督未成年人直播时间的现状，而推出的一种可由家长实施监控，纠正部分未成年子女沉迷直播的保护机制。</span>
        </p>








        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">《未成年人健康参与网络直播提示》</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">随着网络在青少年中的普及，未成年人接触网络直播已经成为普遍现象。为保护未成年人健康参与直播，在政府进一步加强行业管理的前提下，家长也应当加强监护引导。为此，我们为未成年人参与网络直播提供以下意见：</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">一、主动控制直播时间。直播只是学习、生活的调剂，要积极参与线下的各类活动，并让父母了解自己在网络直播中的行为和体验。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">二、不参与可能耗费较多时间的直播设置。不玩大型角色扮演类直播，不玩有PK类设置的直播。在校学生每周玩直播不超过2小时，每月在直播中的花费不超过10元。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">三、不要将直播当作精神寄托。尤其在现实生活中遇到压力和挫折时，应多与家人朋友交流倾诉，不要只依靠直播来缓解压力。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">四、养成积极健康的直播心态。克服攀比、炫耀、仇恨和报复等心理，避免形成欺凌弱小、抢劫他人等不良网络行为习惯。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">五、注意保护个人信息。包括个人家庭、朋友身份信息，家庭、学校、单位地址，电话号码等，防范网络陷阱和网络犯罪。</span>
        </p>








        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">家长监护服务说明：</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">家
长监护系统充分考虑家长的实际需求，当家长发现自己的孩子玩直播过于沉迷的时候，由家长提供合法的监护人资质证明、直播名称账号、以及家长对于限制强度的
愿望等信息，可对处于孩子直播沉迷状态的账号采取几种限制措施，解决未成年人沉迷直播的不良现象，如限制未成年人每天玩直播的时间区间和长度，也可以限制
只有节假日才可以直播，或者完全禁止。</span></p>








        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">家长监护服务进度查询：</span></p>


        <p style="margin:0pt"><span style="font-family:'Times New Roman'; font-size:14px">如果您已经申请家长监护服务，在服务期间，当您对需要提交的信息、处理结果有疑问，或者其他任何问题，您均可以在工作时间联系我们，我们将由专门负责的受理专员为您提供咨询解答服务，或者配合、指导您解决问题。</span>
        </p>
        </div>
    </div>
    <div class="footer">
      <p>Copyright© 2018 - 2022 广州九伴信息科技有限公司 版权所有</p>
      <p>网站备案号   <a href="https://beian.miit.gov.cn">[粤ICP备17090490号-3]</a></p>
      <p>网络文化经营许可证    粤网文 【2018】 8216-2978号</p>
      <p>全国文化市场监督举报电话：12318   注意自我保护，谨防上当受骗</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "convention"
}
</script>

<style scoped>

.banner {
  width: 100%;
  height: 300px;
  background-color: #eeeeee;
  /*position: relative;*/
  background-image: url("../images/home_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /*padding-top: 200px;*/
}

.banner p {
  width: 1200px;
  margin: 0 auto;
  /*text-align: center;*/
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  height: 500px;
  display: flex;
  align-items: center;
}

.main {
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0 80px;
}

.title {
  /*text-align: center;*/
  font-weight: bold;
  font-size: 32px;
  color: #409EFF;
  padding-bottom: 40px;
  margin-bottom: 32px;
  border-bottom: 1px solid #ededed;
}

.main p {
  color: #555555;
  font-size: 14px;
  margin: 16px 0 !important;
  line-height: 24px;
}

.footer {
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  background-color: #222222;
  color: #ffffff;
  padding: 32px 0;
}

.footer a {
  color: #ffffff;
  text-decoration-line: none;
}

.footer p {
  opacity: 0.6;
  margin: 8px 0;
}

</style>
