<template>
  <div class="convention">
    <div class="banner">
      <!--      <p>给大家带来快乐的同时<br/>实现人生价值</p>-->
    </div>
    <div class="main">
      <div class="title">直播公约</div>
      <div>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">一、基本要求</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">主播在本平台直播需遵守以下要求:</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(1) 遵守国家与地方相关法律法规;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(2) 坚持正确导向，坚持积极、健康、向上、向善;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(3) 尊重观众的宗教信仰和风俗习惯;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(4) 言行、着装文明;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(5) 掌握平台互动功能。</span></p>

        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">二、主播要求</span></p>
        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">2.1准入要求</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">2.1.1 主播实名认证</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">主播实名认证需满足下列要求，否则不予通过：</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(1) 申请人应年满 18 周岁，且具有完全民事行为能力;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(2) 虚拟手机号不能进行绑定并开启直播;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(3) 同一个人的有效身份证件(身份证、护照、港澳台居民居住证等)在同一平台上只能认证绑定 一个直播账号;</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(4) 网络主播黑名单的用户，不能通过直播平台的认证审核;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(5) 进行身份认证的用户需要进行人脸活体检测，提供完整清晰正确的有效身份证件姓名及号码，其信息必须与公安系统录入的信息匹配;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(6) 因技术问题不能正常进行身份认证的用户联系平台客服后，按照要求提供相关身份资料，提供 资料交由人工审核。</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">2.1.2 主播银行卡认证</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">主播银行卡认证应满足以下要求，否则不予通过:</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(1)收款人姓名与收款人有效身份证件一经实名认证，无法进行修改;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(2)银行卡开户人等信息必须与主播实名认证时的绑定人信息一致。</span></p>

        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">2.2 直播内容</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">直播内容需要符合下列要求:</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(1) 遵照国家网信办发布的《互联网直播服务管理规定》;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(2) 严禁主播在无保障措施下的情况下直播高风险或威胁生命健康的内容，高危户外活动和需要专 业人士指导的活动需提前向平台报备，取得平台同意后方可直播;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(3) 严禁以任何形式直播无版权内容及低俗不良内容;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(4) 文玩鉴赏类的直播形式必须通过报备，报备者需要一定专业素养，经过相关协会或机构认证的专业人士，无报备者应暂时关闭直播间;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(5) 播放射击、展示枪械、狩猎等相关直播内容，进行户外生存类(在非专业场地野外生存、打猎)的内容，需向本平台提供当地机关许可的书面证明以及直播计划书,需提前联系本平台客服，提供相关报备资料并获得报备通过，报备不通过或无报备者应暂时关闭直播间;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(6) 主播不应在非电商专业直播平台中向观众推销产品;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(7) 主播不应在非金融专业直播平台中宣传投资方式和理财产品，不应向用户承诺和保证收益，不应夸大宣传和虚假宣传;</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(8) 直播画面不应出现广告、支付平台二维码、支付平台账号、商务合作方式等内容;</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(9) 所有的交易行为必须通过第三方正规的电商网站进行。</span></p>

        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">2.3 直播间</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">2.3.1 标题</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">主播设定直播间标题禁止出现以下内容:</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(1) 危害国家安全，泄露国家秘密;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(2) 有损党和国家机构、领导人形象;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(3) 侵犯他人合法权益;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(4) 交易性质的广告;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(5) 低俗、色情信息。</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">2.3.2 封面及主播头像</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">封面及主播头像禁止涉及下列内容的图片:</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(1) 受国家法律法规保护的图案，如人民币、国徽、国旗等;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(2) 含有国家法律法规所不允许的内容;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(3) 宣传鼓动国家分裂性质及地域歧视、性别歧视、民族歧视及 种族歧视的内容;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(4) 违反国家法律法规的邪教组织的人物、标识;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(5) 宣传黄、赌、毒及血腥暴力等内容;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(6) 违规广告或侵权信息;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(7) 含侮辱、挑衅、谩骂人身攻击等不文明不健康信息;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(8) 违反直播平台其他规定规范内容。</span></p>

        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">2.4 行为</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">主播禁止出现以下直播行为:</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(1) 未经直播平台许可，以直播平台名义开展活动;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(2) 涉赌、涉毒、涉黄、涉暴、恐怖等违法行为;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(3) 妨害公共安全或有安全隐患的行为，例如在驾驶机动车期间 进行直播、观看弹幕等;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(4) 诋毁、恶搞、丑化英烈，亵渎、否定英雄事迹和精神，宣扬、美化侵略战争和侵略等行为;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(5) 侵害他人隐私，骚扰、扰民的行为;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(6) 言行过激，传播负面情绪，有违人道主义和道德底线的表演;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(7) 违法擦边行为，例如:意图约架、插队、逃票、翻越围墙、乱写乱画等;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(8) 不及时处理直播间不良倾向弹幕。</span></p>

        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">2.5 场所</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">主播禁止在下列场所进行直播:</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(1) 涉及国家及公共安全的场所;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(2) 涉密场所;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(3) 影响社会正常生产、生活秩序的场所;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(4) 涉及黄、赌、毒等影响社会治安的场所，如成人娱乐场所、夜店酒吧等;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(5) 暴露他人隐私的场所，如洗手间、更衣室等。</span>
        </p>

        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">2.6 着装</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">主播着装禁止过透过露，违反公序良俗，禁止出现以下情形:</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(1) 女主播穿着过于暴露，例如露出胸部、臀部、内衣和内裤等敏感部位;</span>
        </p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(2) 穿着情趣制服，情趣内衣，透视装，肉色紧身衣，内衣外穿等;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(3) 穿着包含国家法律法规禁止出现的文字及图案信息的服装;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(4) 穿着包含不文明或不健康信息的服装;</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(5) 国家公务人员在非政务公开活动中穿着相关制服进行直播;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(6) 其他不雅妆容。</span></p>

        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">三、出现未成年人的直播要求</span></p>
        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">3.1 报备要求</span></p>

        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">直播内容有未成年人单独出镜的需要提前向本平台进行报备，要求如下:</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(1) 提供监护人身份证照片、户口本本人页照片、户口本户主页照片;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(2) 提供被监护人户口本本人页照片;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(3) 由监护人签署《未成年人直播家长监护申请书》，申请书中写明监护人姓名，本人与监护人关 系,将以上内容提交给直播平台审核，审核资料无误后报备通过。</span>
        </p>

        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">3.2 含有未成年人直播内容</span></p>

        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">含有未成年人直播内容禁止出现以下情形:</span></p>


        <p style="margin:0pt"><span
            style="font-family:'微软雅黑'; font-size:14px">(1) 违反《中华人民共和国未成年人保护法》及相关国家法律法规;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(2) 抽烟，酗酒，打架斗殴等宣扬不良生活作风的行为;</span>
        </p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(3) 未经允许的课堂直播;</span></p>


        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">(4) 不适宜未成年人的生活方式和价值观。</span></p>

        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">四、备注</span></p>
        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">4.1本管理办法由颁布，最终解释权归广州九伴信息科技有限公司所有，有权对本办法进行修改并公示。</span>
        </p>

        <br/>
        <p style="margin:0pt"><span style="font-family:'微软雅黑'; font-size:14px">4.2本办法自发布之日起生效。</span></p>
      </div>
    </div>
    <div class="footer">
      <p>Copyright© 2018 - 2022 广州九伴信息科技有限公司 版权所有</p>
      <p>网站备案号   <a href="https://beian.miit.gov.cn">[粤ICP备17090490号-3]</a></p>
      <p>网络文化经营许可证    粤网文 【2018】 8216-2978号</p>
      <p>全国文化市场监督举报电话：12318   注意自我保护，谨防上当受骗</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "convention"
}
</script>

<style scoped>

.banner {
  width: 100%;
  height: 300px;
  background-color: #eeeeee;
  /*position: relative;*/
  background-image: url("../images/home_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /*padding-top: 200px;*/
}

.banner p {
  width: 1200px;
  margin: 0 auto;
  /*text-align: center;*/
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  height: 500px;
  display: flex;
  align-items: center;
}

.main {
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0 80px;
}

.title {
  font-weight: bold;
  font-size: 32px;
  color: #409EFF;
  padding-bottom: 40px;
  margin-bottom: 32px;
  border-bottom: 1px solid #ededed;
}

.main p {
  color: #555555;
  font-size: 14px;
  margin: 8px 0 !important;
}


.footer {
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  background-color: #222222;
  color: #ffffff;
  padding: 32px 0;
}

.footer a {
  color: #ffffff;
  text-decoration-line: none;
}

.footer p {
  opacity: 0.6;
  margin: 8px 0;
}

</style>
